import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

import Layout from "../../../Layout/Layout";
import GratitudeList from "./GratitudeList";
import empty from "../../../../Resources/Images/empty.svg";
import Carousel from "react-bootstrap/Carousel";
import {
  fetchMessagesAction,
  resetFetchMessagesAction,
  makePromoteMsgAction,
  reportMsgAction,
  resetMakePromoteAction,
  resetReportMsgAction,
  listenUpdatedSupportCountAction,
  listenUpdatedReportCountAction,
  listenNewMsgAction,
  listenUpdatedReplyCountAction,
  translateSingleMsgAction,
  resetTranslationMsgAction,
  resetUpdatedSupportCountAction,
  resetUpdatedReportCountAction,
  resetUpdatedReplyCountAction,
  fetchMessageSectorAction,
  shareEmailMsgAction,
  setDittoMsgContent,
  resetDittoMsgContent,
  listenUpdatedDittoCountAction,
  fetchMessagesForSearchAction,
} from "../../../../Actions/communityAction";
import {
  submitGratitudeMsgAction,
  resetGratitudeMsgAction,
} from "../../../../Actions/gratitudeAction";
import { changeHeaderAction } from "../../../../Actions/generalAction";
import CustomizedSnackbars from "../../../Common/CustomizedSnackbars";
import Model from "../../../Common/Model";
import ModelDitto from "../../../Common/ModelDitto";
import ModelDonation from "../../../Common/ModelDonation";
import ModelGiveLittle from "../../../Common/ModelGiveLittle";
import EmailModel from "../../../Common/ModalShareEmail";
import SocialMediaSharingModel from "../../../Common/SocialMediaShareModel";
import Reply from "./Reply";
import WriteReply from "./WriteReply";
import Ditto from "./Ditto";
import { getLanguage } from "../../../../Utils/Storage";

import LoadingAnimation from "../../../Common/LoadigAnimation";

import StoryList from "./StoryList";
import stories from "../../../../Resources/Files/communityData";
import { useHistory } from "react-router-dom";
import StoryItem from "../../Stories/Pages/StoryItem";
import storyData from "../../../../Resources/Files/communityData";
import { shuffleStories } from "../../../../Utils/FormatData";
import {
  getGeoLocationByLatLong,
  getGeocodeByIPaddress,
  getGeoCodeByAddress,
  getContinentByCoutryCode,
} from "../../../../Utils/GoogleAPI";

import SearchBar from "../../Search/Search";

const Community = (props) => {
  var history = useHistory();
  const { t, i18n } = useTranslation();

  const [isFirstAttemt, setIsFirstAttemt] = useState(true);
  const [isFilteringMsg, setIsFilteringMsg] = useState(false);

  const [skipAmount, setSkiipAmount] = useState(0);
  const [fetchLimit, setFetchLimit] = useState(10);

  const [listItems, setListItems] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [singleMsgData, setSingleMsgData] = useState({});

  const [isOpenDittoComponent, setIsOpenDittoComponent] = useState(false);

  //Ditto user list
  const [dittoUserList, setDittoUserList] = useState([]);

  //listen to new msg
  var [listenToNewMsg, setListenToNewMsg] = useState(false);

  //report message id
  const [reportMsgID, setReportMsgID] = useState([]);

  //report message id
  const [donationMsg, setDonationMsg] = useState([]);

  // Notification message
  // Notification
  const [errorNotifification, setErrorNotifification] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [severity, setSeverity] = useState("");

  // Model
  const [openModel, setOpenModel] = useState(false);
  const [modelHeader, setModelHeader] = useState("");
  const [modelContent, setModelContent] = useState("");
  const [modelButton1, setModelButton1] = useState("");
  const [modelButton2, setModelButton2] = useState("");

  // Model Ditto
  const [openModelDitto, setOpenModelDitto] = useState(false);
  const [modelHeaderDitto, setModelHeaderDitto] = useState("");
  const [modelContentDitto, setModelContentDitto] = useState("");
  const [modelButton1Ditto, setModelButton1Ditto] = useState("");
  const [modelButton2Ditto, setModelButton2Ditto] = useState("");

  // Model Donation
  const [openModelDonation, setOpenModelDonation] = useState(false);
  const [openModelGiveLittle, setOpenModelGiveLittle] = useState(false);
  const [modelDonationHeader, setModelDonationHeader] = useState("");
  const [modelDonationContent, setModelDonationContent] = useState("");
  const [modelDonationButton1, setModelDonationButton1] = useState("");
  const [modelDonationButton2, setModelDonationButton2] = useState("");
  const [modelDonationSubHeader, setModelDonationSubHeader] = useState("");

  // Model social media
  const [openSocialMediaModel, setOpenSocialMediaModel] = useState(false);

  // New message arrive
  const [hasNewMsg, setHasNewMsg] = useState(false);

  // Reply msg
  const [showReply, setShowReply] = useState(false);
  const [showWriteMsg, setShowWriteMsg] = useState(false);

  const [translatedMsgs, setTranslatedMsgs] = useState([]);

  const [openModelEmail, setOpenModelEmail] = useState(false);
  const [shareMsgHeader, setShareMsgHeader] = useState(false);

  const [emailShareSuccess, setEmailShareSuccess] = useState();

  const [stories, setStories] = useState(storyData);
  const [senderLocation, setSenderLocation] = useState("");

  const [windowScrollPosition, setWindowScrollPosition] = useState(0);
  const [yPosition, setYPosition] = useState();

  const [isSubmitDitto, setIsSubmitDitto] = useState(false);

  //screen controll
  const [winHeight, setWinHeight] = useState(0.0);
  const [scrollP, setScrollP] = useState(0.0);

  //search function
  const [searchfieldEmpty, setSearchfieldEmpty] = useState(true);
  const [isfetchingSearchList, setIsfetchingSearchList] = useState(false);
  const [isQueryingData, setIsQueryingData] = useState(false);

  // useEffect(() => {
  //     return () => {
  //         if (props.communitySocket !== null) {
  //             props.communitySocket.disconnect();
  //         }
  //     }
  // }, []);

  useEffect(() => {
    /* Reset ditto msg content */
    props.resetDittoMsgContent();

    var limit = fetchLimit;
    var defaultSkipAmount = skipAmount;
    getData(defaultSkipAmount, limit);
    //shuffle the stories list, except for featured items
    let shuffledStories = shuffleStories(stories);
    setStories(shuffledStories);
    setIsQueryingData(true);

    //set yPosition state if there's a value in the storage
    // let scrollYPosition = getScrollYPosition();
    // if(scrollYPosition){
    //     setYPosition(scrollYPosition);
    // }

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    /* scroll down to last scroll position */
    if (!isOpenDittoComponent) window.scrollTo(0, windowScrollPosition);
  }, [isOpenDittoComponent]);

  useEffect(() => {
    props.listenUpdatedSupportCountAction();
    props.listenUpdatedReportCountAction();
    props.listenUpdatedReplyCountAction();
    props.listenNewMsgAction();
    props.fetchMessageSectorAction();
    props.listenUpdatedDittoCountAction();
    setShowReply(false);
    props.changeHeaderAction({ type: "main", page: "community" });
  }, []);

  // Update msg support count
  useEffect(() => {
    if (props.updatedSupportCount.support) {
      var messages = listItems;
      const index = listItems.findIndex(
        (msg) => msg._id === props.updatedSupportCount._id
      );
      if (index != -1) {
        messages[index].support.count = props.updatedSupportCount.support.count;
        setListItems([...messages]);
      }
      props.resetUpdatedSupportCountAction();
    }
  }, [props.updatedSupportCount]);

  // Update msg report count
  useEffect(() => {
    if (props.updatedReportCount.indecentReport) {
      var messages = listItems;
      const index = listItems.findIndex(
        (msg) => msg._id === props.updatedReportCount._id
      );
      if (index != -1) {
        messages[index].shouldMessageVisible =
          props.updatedReportCount.indecentReport.count >= 3 ? false : true;
        setListItems([...messages]);
      }
      props.resetUpdatedReportCountAction();
    }
  }, [props.updatedReportCount]);

  // Update reply count
  useEffect(() => {
    if (props.updatedReplyCount._id) {
      var messages = listItems;
      const index = listItems.findIndex(
        (msg) => msg._id === props.updatedReplyCount._id
      );
      if (index != -1) {
        messages[index].replyCount = props.updatedReplyCount.replyCount;
        setListItems([...messages]);
      }
      props.resetUpdatedReplyCountAction();
    }
  }, [props.updatedReplyCount]);

  // If arrive new message
  useEffect(() => {
    if (props.latestMsg.hasOwnProperty("message") && listenToNewMsg) {
      var index = listItems.findIndex((msg) => msg._id === props.latestMsg._id); // Check msg inside the array

      if (index === -1) {
        // Msg is not inside the array
        setListItems([props.latestMsg, ...listItems]);
        var scroll = window.innerHeight + document.documentElement.scrollTop;

        scroll > 600 ? setHasNewMsg(true) : setHasNewMsg(false);
      }
    }
    setListenToNewMsg(true);
  }, [props.latestMsg]);

  // Update msg ditto count
  useEffect(() => {
    if (props.updatedDittoCount.state === "SUCCESS") {
      var messages = listItems;
      const index = listItems.findIndex(
        (msg) => msg._id === props.updatedDittoCount.omId
      );
      if (index != -1) {
        messages[index].ditto.dittoCount = props.updatedDittoCount.dittoCount;
        messages[index].ditto.dittoedUsers =
          props.updatedDittoCount.dittoedUsers;
        setListItems([...messages]);
      }
      props.resetUpdatedSupportCountAction();
    }
  }, [props.updatedDittoCount]);

  const handleScroll = () => {
    var elmnt = document.getElementById("list");
    var windowHeight = window.innerHeight + document.documentElement.scrollTop;
    if (!elmnt) return;
    var scroll = elmnt.offsetHeight;
    setWinHeight(windowHeight);
    setScrollP(scroll);
  };

  useEffect(() => {
    if (winHeight > scrollP && !isFetching && searchfieldEmpty) {
      setIsFetching(true);
      setIsQueryingData(true);
    }
    if (winHeight > scrollP && !isfetchingSearchList) {
      setIsfetchingSearchList(true);
      fetchMessagesForSearch();
    }
  }, [scrollP, winHeight]);

  useEffect(() => {
    if (!isFetching) return;

    var limit = fetchLimit;
    var defaultSkipAmount = skipAmount + 10;

    setSkiipAmount(defaultSkipAmount);

    getData(defaultSkipAmount, limit);
  }, [isFetching]);

  useEffect(() => {
    if (props.messages) {
      if (!isFilteringMsg) {
        if (props.messages.success) {
          setListItems([...listItems, ...props.messages.messages]);
          props.resetFetchMessagesAction();
          setIsLoading(false);
        }
      } else {
        if (props.messages.success) {
          setListItems([...props.messages.messages]);
          setIsFilteringMsg(false);
          props.resetFetchMessagesAction();
          setIsLoading(false);
        }
      }
      // else if (props.messages.data.success) {
      //     setListItems([...listItems, ...props.messages.data.messages]);
      // }
      setIsFetching(false);
      setIsFirstAttemt(false);
    }
  }, [props.messages]);

  const getData = (defaultSkipAmount, limit) => {
    let locationIdList = props.locationFilter
      ? props.locationFilter.map((locObj) => locObj.placeId)
      : [];
    var formData = {
      limit: limit,
      skip: defaultSkipAmount,
      locations: locationIdList ? locationIdList : [],
      recipients: props.recipientFilter ? props.recipientFilter : [],
    };
    props.fetchMessagesAction(formData);
    setIsLoading(true);
  };

  useEffect(() => {
    if (!isFirstAttemt) {
      var limit = 10;
      var defaultSkipAmount = 0;

      setSkiipAmount(defaultSkipAmount);
      setFetchLimit(limit);

      setIsFilteringMsg(true);
      getData(defaultSkipAmount, limit);
    }
  }, [props.locationFilter, props.recipientFilter]);

  // Support for message
  const makePromo = (msgData) => {
    var formData = {
      _id: msgData._id,
      locations: msgData.locations,
    };

    props.makePromoteMsgAction(formData);
  };

  useEffect(() => {
    if (props.makePromote.support) {
      if (props.makePromote.support) {
        var updatedID = props.makePromote._id;

        // Update count
        listItems.find((msg) => msg._id === updatedID).support.count =
          props.makePromote.support.count;
        listItems.find((msg) => msg._id === updatedID).support.supportedHashes =
          props.makePromote.support.supportedHashes;

        setListItems([...listItems]);
        // setSeverity("success");
        // setErrorMsg(t('Thank you for your feedback'));
        // setErrorNotifification(!errorNotifification);
        props.resetMakePromoteAction();
      } else {
        setSeverity("error");
        setErrorMsg(t("Ooops..Some thing went wrong..!"));
        setErrorNotifification(!errorNotifification);
        props.resetMakePromoteAction();
      }
    }
  }, [props.makePromote]);

  // Donation
  const donate = (dataMsg) => {
    var modelContent = "";
    var button = "";
    var header = "";
    var subHeader = "";

    header = `${t("Support")} ${dataMsg.author}`;
    dataMsg.charity.modelContent.map((donation) => {
      if (
        donation.language == props.language ||
        donation.language == `${props.language}-TW`
      ) {
        subHeader = donation.modelHeadline;
        modelContent =
          donation.paraSeg01 +
          " <b>" +
          dataMsg.charity.charity.name +
          "</b > " +
          donation.paraSeg02;
      }
    });

    button = "Donation";
    setDonationMsg(dataMsg);
    setModelDonationHeader(header);
    setModelDonationSubHeader(subHeader);
    setModelDonationContent(modelContent);
    setModelDonationButton1(button);
    setModelDonationButton2("Cancel");
    setOpenModelGiveLittle(true);
    // setOpenModelDonation(true);
  };

  // Report message
  const reportMsgModel = (dataMsg, isReported) => {
    var modelContent = "";
    var button = "";
    var header = "";
    if (isReported) {
      header = t("Unreport");
      modelContent = t("To undo your action please select Unreport msg");
      button = t("Unreport");
    } else {
      header = t("Report");
      modelContent = t(
        "Please select Report below you feel this messge should be removed msg"
      );
      button = t("Report");
    }

    setReportMsgID(dataMsg);
    setModelHeader(header);
    setModelContent(modelContent);
    setModelButton1(button);
    setModelButton2(t("cancel"));
    setOpenModel(!openModel);
  };

  const reportMsg = () => {
    var formData = {
      _id: reportMsgID._id,
      locations: reportMsgID.locations,
    };

    props.reportMsgAction(formData);
  };

  useEffect(() => {
    if (props.reportMsg.indecentReport) {
      if (props.reportMsg.indecentReport) {
        var updatedID = props.reportMsg._id;
        var reportCount = props.reportMsg.indecentReport.count;
        // Update count
        listItems.find(
          (msg) => msg._id === updatedID
        ).indecentReport.reportedHashes =
          props.reportMsg.indecentReport.reportedHashes;

        listItems.find(
          (msg) => msg._id === updatedID
        ).indecentReport.shouldMessageVisible = reportCount >= 3 ? false : true;

        setListItems([...listItems]);

        // setSeverity("success");
        // setErrorMsg(t('Thank you for your feedback'));
        // setErrorNotifification(!errorNotifification);
        setOpenModel(!openModel);
        props.resetReportMsgAction();
      } else {
        setSeverity("error");
        setErrorMsg(t("Ooops..Some thing went wrong..!"));
        setErrorNotifification(!errorNotifification);
        setOpenModel(!openModel);
        props.resetReportMsgAction();
      }
    }
  }, [props.reportMsg]);

  // Share message
  const shareMsg = (msgData) => {
    msgData.shareUrl = `${process.env.REACT_APP_LIVE_URL}/msg/${msgData._id}`;
    setSingleMsgData(msgData);
    setOpenSocialMediaModel(!openSocialMediaModel);
    setShareMsgHeader(t("Share your message"));
  };

  //Close error notification
  const closeErrorNotifification = () => {
    setErrorNotifification(!errorNotifification);
  };

  const scrollUp = () => {
    window.scrollTo(0, 0);
    setHasNewMsg(false);
  };

  // Replay msg
  const replyMsg = (dataList) => {
    setSingleMsgData(dataList);
    if (dataList.replyCount > 0) setShowReply(!showReply);
    else setShowWriteMsg(!showWriteMsg);
    props.changeHeaderAction({
      type: "reply",
      headerTitle: `${dataList.author}`,
    });

    //store scroll Y position in localstorage
    setYPosition(window.scrollY);
  };

  const closeReply = () => {
    setShowReply(false);
    setShowWriteMsg(false);
    props.changeHeaderAction({ type: "main" });

    setTimeout(function () {
      window.scrollTo(0, yPosition);
    }, 200);
  };

  const closeWriteReply = (openReplyComponent) => {
    if (openReplyComponent === true) {
      props.changeHeaderAction({
        type: "reply",
        headerTitle: `${singleMsgData.author}`,
      });
      setShowReply(true);
    } else {
      setShowReply(false);
      props.changeHeaderAction({ type: "main" });
    }
    setShowWriteMsg(false);

    setTimeout(function () {
      window.scrollTo(0, yPosition);
    }, 200);
  };

  // If translate msg
  useEffect(() => {
    if (
      props.translatedMsg &&
      props.translatedMsg.status == 200 &&
      props.translatedMsg.hasOwnProperty("data")
    ) {
      if (props.translatedMsg.data.hasOwnProperty("translatedMessage")) {
        var messages = listItems;
        const index = listItems.findIndex(
          (msg) => msg._id === props.translatedMsg.data.translatedMessage._id
        );
        messages[index].message =
          props.translatedMsg.data.translatedMessage.message;
        // messages[index].indecentReport.shouldMessageVisible = props.updatedMsg.updatedMessage.indecentReport.count >= 3 ? false : true;
        setListItems([...messages]);
        props.resetTranslationMsgAction();
      }
    }
  }, [props.translatedMsg]);

  //translate msg
  const translateMsg = (msgData) => {
    let lang = "";
    let messages = translatedMsgs;
    let index = translatedMsgs.findIndex((msg) => msg._id === msgData._id);
    if (index != -1) {
      let isTranslatedMsg = translatedMsgs[index].isTranslated;
      if (isTranslatedMsg) {
        lang = "";
        messages[index].isTranslated = false;
      } else {
        lang = getLanguage();
        messages[index].isTranslated = true;
      }
    } else {
      lang = getLanguage();
      messages.push({
        _id: msgData._id,
        isTranslated: true,
      });
    }

    setTranslatedMsgs([...messages]);

    var formData = {
      id: msgData._id,
      lang: lang,
    };

    props.translateSingleMsgAction(formData);
  };

  useEffect(() => {
    if (props.language) {
      let messages = translatedMsgs;
      for (var i = 0, l = messages.length; i < l; i++) {
        var obj = messages[i];
        if (obj.isTranslated) {
          var formData = {
            id: obj._id,
            lang: "",
          };

          obj.isTranslated = false;
          messages[i] = obj;

          props.translateSingleMsgAction(formData);
        }
      }
      //props.resetTranslationMsgAction();
      setTranslatedMsgs([...messages]);
    }
  }, [props.language]);

  const openEmailModal = () => {
    setOpenModelEmail(true);
  };

  const closeEmailModel = () => {
    setOpenModelEmail(false);
  };

  // const validateEmailForm = (data) => {
  // }

  const submitEmailShareForm = (data) => {
    data.messageId = singleMsgData._id;
    data.shareUrl = singleMsgData.shareUrl;
    data.selectedLanguage = getLanguage();
    props.shareEmailMsgAction(data);
  };

  useEffect(() => {
    if (props.shareMsg) {
      if (props.shareMsg.success) {
        setEmailShareSuccess(true);
      } else {
        setEmailShareSuccess(false);
      }

      setTimeout(function () {
        closeEmailModel();
        setEmailShareSuccess("");
      }, 3000);
    }
  }, [props.shareMsg]);

  /* Ditto Msg */
  const dittoMsg = (data) => {
    props.setDittoMsgContent(data);
    if (props.userPrecense.data.isRegistered) {
      setOpenModelDitto(true);
      setModelHeaderDitto(t("Repost Message"));
      setModelContentDitto(t("Do you want Repost this message ?"));
      setModelButton1Ditto(t("Repost"));
      setModelButton2Ditto(t("cancel"));
      navigator.geolocation.getCurrentPosition(setLatLong, setByIPaddress);
    } else {
      history.push("/gratitude");
    }
  };

  /*Ditto user list component */
  const dittoComponent = (userList) => {
    var windowHeight = window.scrollY;
    setWindowScrollPosition(windowHeight);

    setIsOpenDittoComponent(true);
    setDittoUserList(userList);
  };

  /* Post ditto msg directly */
  const postDittoMsg = async (impactOfMsg) => {
    var formData = {
      message: props.ditto.message,
      taggedRecipient: props.ditto.taggedRecipient,
      recipientsLocation: props.ditto.recipientsLocation,
      senderLoactions: senderLocation.location,
      author: props.userPrecense.data.user.userName,
      authorLocation: senderLocation.description,
      locations: props.ditto.locations,
      charity: props.ditto.charity,
      recipientName: props.ditto.recipientName,
      recipientEmail: props.ditto.recipientEmail,
      isImpactEnable: impactOfMsg,
      isDitto: true,
      omId: props.ditto._id,
      charity: props.ditto.charity,
      messageIcon: props.ditto.messageIcon,
      language: props.ditto.language,
    };

    // Set is submit ditto
    setIsSubmitDitto(true);

    //   setEnableSubmitButton(false);
    props.submitGratitudeMsgAction(formData);
  };

  // Geo location get by geo code
  const setLatLong = async (geoLocation) => {
    var lat = geoLocation.coords.latitude;
    var long = geoLocation.coords.longitude;
    var response = await getGeoLocationByLatLong(lat, long);
    const arLength = response.results.length;
    const geoLoc = response.results[arLength - 3].formatted_address;

    const cityData = await getGeoCodeByAddress(
      response.results[arLength - 3].address_components[0].long_name
    );
    const countryData = await getGeoCodeByAddress(
      response.results[arLength - 1].address_components[0].long_name
    );

    setSenderLocation({
      latitude: lat,
      longitude: long,
      description: geoLoc,
      location: [
        {
          name: {
            offset: "auto",
            value:
              response.results[arLength - 3].address_components[0].long_name,
          },
          type: "city",
          placeId: cityData[0].place_id,
        },
        {
          name: {
            offset: "auto",
            value:
              response.results[arLength - 1].address_components[0].long_name,
          },
          type: "country",
          placeId: countryData[0].place_id,
        },
      ],
    });
  };

  // Geo location get by ip
  const setByIPaddress = async (error) => {
    var geoData = await getGeocodeByIPaddress();
    var geoCodeAr = geoData.loc.split(",");
    var lat = geoCodeAr[0];
    var long = geoCodeAr[1];
    var response = await getGeoLocationByLatLong(lat, long);

    const arLength = response.results.length;
    const geoLocation = response.results[arLength - 3].formatted_address;

    const cityData = await getGeoCodeByAddress(
      response.results[arLength - 3].address_components[0].long_name
    );
    const countryData = await getGeoCodeByAddress(
      response.results[arLength - 1].address_components[0].long_name
    );

    setSenderLocation({
      latitude: lat,
      longitude: long,
      description: geoLocation,
      location: [
        {
          name: {
            offset: "auto",
            value:
              response.results[arLength - 3].address_components[0].long_name,
          },
          type: "city",
          placeId: cityData[0].place_id,
        },
        {
          name: {
            offset: "auto",
            value:
              response.results[arLength - 1].address_components[0].long_name,
          },
          type: "country",
          placeId: countryData[0].place_id,
        },
      ],
    });
  };

  /* submit ditto msg response */
  useEffect(() => {
    if (isSubmitDitto) {
      if (props.submitGratitudeMsg) {
        if (props.submitGratitudeMsg.success) {
          // setSeverity("success");
          // setErrorMsg(t('Wow..! Ditto success..!'));
          // setErrorNotifification(!errorNotifification);
          window.scrollTo(0, 0);
        } else if (props.submitGratitudeMsg.error) {
          // setSeverity("error");
          // setErrorMsg(props.submitGratitudeMsg.error.message);
          // setErrorNotifification(!errorNotifification);
        }
        setOpenModelDitto(false);
        props.resetDittoMsgContent(); // Reset ditto msg content
        props.resetGratitudeMsgAction();
      }
      setIsSubmitDitto(false);
    }
  }, [props.submitGratitudeMsg]);

  // ************************************************* search function *******************************************

  const fetchMessagesForSearch = () => {
    let locationIdList = props.locationFilter
      ? props.locationFilter.map((locObj) => locObj.placeId)
      : [];
    let formData = {
      limit: 100,
      skip: props.curSkip,
      locations: locationIdList ? locationIdList : [],
      recipients: props.recipientFilter ? props.recipientFilter : [],
    };
    props.fetchMessagesForSearchAction(formData);
  };

  useEffect(() => {
    if (props.messageSearchString.length > 0) {
      setIsLoading(true);
      setListItems([]);
      setSearchfieldEmpty(false);
      window.scrollTo(0, 0);
      setIsFetching(false);
    } else {
      setSearchfieldEmpty(true);
      setIsLoading(true);
      setListItems([]);
    }
  }, [props.messageSearchString]);

  useEffect(() => {
    if (!searchfieldEmpty) {
    }
  }, [searchfieldEmpty]);

  useEffect(() => {
    setTimeout(function () {
      if (props.filteredMessageList.length) {
        if (props.messageSearchString.length) {
          setIsLoading(false);
          setListItems(props.filteredMessageList);
          setIsFirstAttemt(false); // to trigger the else block, set this to false when searching
        }

        setIsQueryingData(false);
      } else {
        //if length of search string and filtered list is empty and gerData query is already called through this block and
        //this is not the fist attemt of rendering the community feed
        if (
          !props.messageSearchString.length &&
          !props.filteredMessageList.length &&
          !isQueryingData &&
          !isFirstAttemt
        ) {
          window.scrollTo(0, 0);
          setListItems([]);
          setIsLoading(false);
          let limit = 10;
          let defaultSkipAmount = 0;
          getData(defaultSkipAmount, limit); //onsole.log("get data called from search list");
          setIsQueryingData(true); // to prevent rendering this block of code
        }
      }
      //if there are no search results
      if (
        props.messageSearchString.length &&
        !props.filteredMessageList.length
      ) {
        setListItems([]);
        setIsLoading(false);
        setIsFirstAttemt(false);
        setIsQueryingData(false);
      }
    }, 1000);
  }, [props.filteredMessageList]);

  useEffect(() => {
    if (isfetchingSearchList) {
      setIsfetchingSearchList(false);
    }
  }, [props.messageListForSearch]);

  useEffect(() => {
    //window.scrollTo(0, 0);
    if (props.filteredMessageList.length) {
      setListItems(props.filteredMessageList);
    }
  }, [props.filteredMessageList]);

  return (
    <Layout>
      {!isOpenDittoComponent && !showReply && !showWriteMsg && (
        <div className="row">
          <div id="list" className="massage-list col-12 dwwdwd">
            <Carousel fade={true} controls={false}>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="smilee ban"
                  href="https://youtu.be/qnIniMHL38Y"
                >
                  <div>
                    <p>https://humansofsharegratitude.com</p>
                    <h1>The Seven Wonders of Gratitude</h1>
                    <button className="btn btn-secondary">WATCH NOW</button>
                  </div>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="uk ban"
                  href="https://gofund.me/fa2c0fce"
                >
                  <div>
                    <p>Our Healthcare Workers Are Extraordinarily HUMAN!</p>
                    <h1>We need your support</h1>
                    <button className="btn btn-secondary">Give Now</button>
                  </div>
                </a>
              </Carousel.Item>
              <Carousel.Item>
                <a
                  target="_blank"
                  className="smilee ban"
                  href="https://www.youtube.com/watch?v=MEqxa0l6FqA&t"
                >
                  <div>
                    <p>https://humansofsharegratitude.com</p>
                    <h1>...being extraordinarily HUMAN</h1>
                    <button className="btn btn-secondary">WATCH NOW</button>
                  </div>
                </a>
              </Carousel.Item>
              </Carousel>
              {/* <Carousel.Item>
                <a
                  target="_blank"
                  className="ses ban"
                  href="https://www.ses.nsw.gov.au/get-involved/donations/"
                >
                  <div>
                    <p>Make a Donation to NSW SES and Volunteers</p>
                    <h1>LETS SUPPORT OUR SES</h1>
                    <button className="btn btn-secondary">Donate Today</button>
                  </div>
                </a>
              </Carousel.Item>

              <Carousel.Item>
                <a
                  target="_blank"
                  className="sesq ban"
                  href="https://www.qfes.qld.gov.au/about-us/frontline-services/state-emergency-service/ses-donations"
                >
                  <div>
                    <p>Make a Donation to QLD SES and Volunteers</p>
                    <h1>LETS SUPPORT OUR SES</h1>
                    <button className="btn btn-secondary">Donate Today</button>
                  </div>
                </a>
              </Carousel.Item>
            </Carousel> */}

            {props.changeHeader.page === "community" && <SearchBar />}
            {listItems.length !== 0 &&
              listItems.map((data, key) =>
                data.shouldMessageVisible ? (
                  <Fragment key={key}>
                    {/* {stories.length !== 0 && (listItems.length +1)/3 > stories.length && key%3==0 && stories[(key - stories.length*3)/3-1] ?

                                        <>
                                            <StoryItem 
                                                dataList={stories[(key - stories.length*3)/3-1]}
                                            />
                                        </>
                                    :null
                                    }                                */}
                    {!props.messageSearchString.length > 0 ? (
                      stories.length !== 0 &&
                      key % 3 == 0 &&
                      key != 0 &&
                      stories[key / 3] ? (
                        <>
                          <StoryItem dataList={stories[key / 3]} />
                        </>
                      ) : stories.length !== 0 && key == 0 ? (
                        <StoryItem dataList={stories[key]} />
                      ) : null
                    ) : null}
                    <GratitudeList
                      key={data._id}
                      dataList={data}
                      makePromo={makePromo}
                      reportMsg={reportMsgModel}
                      //shareMsg={shareMsg}
                      //temporary allow email share only
                      shareMsg={openEmailModal}
                      replyMsg={replyMsg}
                      translateMsg={translateMsg}
                      donation={donate}
                      openDittoComponent={(dittoUsers) =>
                        dittoComponent(dittoUsers)
                      }
                      dittoMsg={(data) => dittoMsg(data)}
                    />
                  </Fragment>
                ) : (
                  ""
                )
              )}
            {listItems.length === 0 && !isLoading && (
              <>
                <div className="empty-state">
                  <img src={empty}></img>
                  <p>{t("There are no messages to display")}</p>
                </div>
              </>
            )}
            {((listItems.length === 0 && isLoading) || isLoading) &&
              [...Array(10)].map((e, i) => <LoadingAnimation key={i} />)}
          </div>
        </div>
      )}

      {showReply && !showWriteMsg && (
        <Reply msgData={singleMsgData} closeReply={closeReply} />
      )}

      {!showReply && showWriteMsg && (
        <WriteReply msgData={singleMsgData} closeWriteReply={closeWriteReply} />
      )}

      {isOpenDittoComponent && (
        <Ditto
          closeDittoComponent={() => setIsOpenDittoComponent(false)}
          userList={dittoUserList}
        />
      )}

      <CustomizedSnackbars
        closePopup={closeErrorNotifification}
        popupOpen={errorNotifification}
        notifyMsg={errorMsg}
        severity={severity}
        autoHideDuration={2000}
      />

      <Model
        open={openModel}
        modelHeader={modelHeader}
        modelContent={modelContent}
        button1={modelButton1}
        button2={modelButton2}
        trigerFunction={reportMsg}
        closeModel={() => setOpenModel(!openModel)}
      />

      <ModelDitto
        open={openModelDitto}
        modelHeader={modelHeaderDitto}
        modelContent={modelContentDitto}
        button1={modelButton1Ditto}
        button2={modelButton2Ditto}
        trigerFunction={postDittoMsg}
        closeModel={() => setOpenModelDitto(!openModelDitto)}
      />

      <ModelGiveLittle
        open={openModelGiveLittle}
        donationMsg={donationMsg}
        modelHeader={modelDonationHeader}
        subHeader={modelDonationSubHeader}
        modelContent={modelDonationContent}
        button1={modelDonationButton1}
        button2={modelDonationButton2}
        closeModel={() => setOpenModelGiveLittle(false)}
      />

      {/* <ModelDonation
                open={openModelDonation}
                donationMsg={donationMsg}
                modelHeader={modelDonationHeader}
                subHeader={modelDonationSubHeader}
                modelContent={modelDonationContent}
                button1={modelDonationButton1}
                button2={modelDonationButton2}
                closeModel={() => setOpenModelDonation(false)}
            /> */}

      <SocialMediaSharingModel
        shareMsgData={singleMsgData}
        open={openSocialMediaModel}
        closeModel={() => setOpenSocialMediaModel(!openSocialMediaModel)}
        openEmailModal={openEmailModal}
      />

      <EmailModel
        open={openModelEmail}
        modelHeader={shareMsgHeader}
        closeModel={closeEmailModel}
        shareMsgData={singleMsgData}
        submitForm={(data) => submitEmailShareForm(data)}
        emailShareSuccess={emailShareSuccess}
      />
    </Layout>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchMessagesAction: (data) => dispatch(fetchMessagesAction(data)),
    resetFetchMessagesAction: () => dispatch(resetFetchMessagesAction()),
    makePromoteMsgAction: (data) => dispatch(makePromoteMsgAction(data)),
    reportMsgAction: (data) => dispatch(reportMsgAction(data)),
    resetMakePromoteAction: () => dispatch(resetMakePromoteAction()),
    resetReportMsgAction: () => dispatch(resetReportMsgAction()),
    listenUpdatedSupportCountAction: () =>
      dispatch(listenUpdatedSupportCountAction()),
    listenUpdatedReportCountAction: () =>
      dispatch(listenUpdatedReportCountAction()),
    listenUpdatedReplyCountAction: () =>
      dispatch(listenUpdatedReplyCountAction()),
    listenNewMsgAction: () => dispatch(listenNewMsgAction()),
    translateSingleMsgAction: (data) =>
      dispatch(translateSingleMsgAction(data)),
    resetTranslationMsgAction: () => dispatch(resetTranslationMsgAction()),
    changeHeaderAction: (data) => dispatch(changeHeaderAction(data)),
    resetUpdatedSupportCountAction: () =>
      dispatch(resetUpdatedSupportCountAction()),
    resetUpdatedReportCountAction: () =>
      dispatch(resetUpdatedReportCountAction()),
    resetUpdatedReplyCountAction: () =>
      dispatch(resetUpdatedReplyCountAction()),
    fetchMessageSectorAction: () => dispatch(fetchMessageSectorAction()),
    shareEmailMsgAction: (data) => dispatch(shareEmailMsgAction(data)),
    setDittoMsgContent: (data) => dispatch(setDittoMsgContent(data)),
    submitGratitudeMsgAction: (data) =>
      dispatch(submitGratitudeMsgAction(data)),
    resetGratitudeMsgAction: () => dispatch(resetGratitudeMsgAction()),
    resetDittoMsgContent: () => dispatch(resetDittoMsgContent()),
    listenUpdatedDittoCountAction: () =>
      dispatch(listenUpdatedDittoCountAction()),
    fetchMessagesForSearchAction: (data) =>
      dispatch(fetchMessagesForSearchAction(data)),
  };
};

const mapStateToProps = (response) => ({
  messages: response.community.messages,
  makePromote: response.community.makePromote,
  reportMsg: response.community.reportMsg,
  userPrecense: response.general.userPrecense,
  updatedSupportCount: response.community.updatedSupportCount,
  updatedReportCount: response.community.updatedReportCount,
  updatedReplyCount: response.community.updatedReplyCount,
  latestMsg: response.community.latestMsg,
  locationFilter: response.community.locationFilter,
  recipientFilter: response.community.recipientFilter,
  translatedMsg: response.community.translatedMsg,
  communitySocket: response.community.communitySocket,
  language: response.general.lang,
  shareMsg: response.community.shareMsg,
  ditto: response.community.ditto,
  submitGratitudeMsg: response.gratitude.submitGratitudeMsg,
  updatedDittoCount: response.community.updatedDittoCount,
  messageListForSearch: response.community.search.messageListForSearch,
  messageSearchString: response.community.search.messageSearchString,
  filteredMessageList: response.community.search.filteredMessageList,
  curSkip: response.community.search.skip,
  changeHeader: response.general.changeHeader,
});
export default connect(mapStateToProps, mapDispatchToProps)(Community);
