import React from 'react';
import { Widget } from "@typeform/embed-react";
import Layout from '../../../Layout/Layout';
import { useTranslation } from "react-i18next";

export default function Contact() {
  return (
    <Layout>
    <div className="col-md-12  pb-5">
<div className="row">
<div className="col-sm-12 col-md-12 col-lg-12">
  <div className="input-list bt-0 pt-0">
    <h3 className="tit mb-3">Contact Us</h3>
    <div className="row">
      <div className="typewrap">
        <Widget
          id="muYE5iBp"
          style={{ width: "100%", height: 500 }}
          className="my-form"
        />
      </div>
    </div>
  </div>
</div>
</div>
</div>
</Layout>
  )
}
